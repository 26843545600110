<template>
   <div class="hold-transition register-page">
      <div class="register-box">
         <div class="card card-outline card-primary">
            <div class="card-header text-center">
               <img
                  style="width: 300px; height: 60px; border-radius: 0%;"
                  class="img-size-50 img-circle mb-3 mr-2"  src="/static/dist/img/logo.png" alt="logo image">
            </div>
            <div class="card-body">
               <p class="login-box-msg text-secondary font-italic" style="font-size: 12px">
                  Preencha as informações primeiro antes de prosseguir com o pagamento!
               </p>

               <form>
                  <div class="input-group mb-3">
                     <input v-model="v$.user.name.$model" type="text" class="form-control" placeholder="Nome">
                     <div class="input-group-append">
                        <div class="input-group-text">
                           <span class="fas fa-user"></span>
                        </div>
                     </div>
                     <div v-if="v$.user.name.$error" class="col-12 text-danger font-italic small-text">
                        <span >{{ errorMessage(v$.user.name.$errors) }}</span>
                     </div>
                  </div>
                  <div class="input-group mb-3">
                     <input v-model="v$.user.email.$model" type="email" class="form-control" placeholder="Email">
                     <div class="input-group-append">
                        <div class="input-group-text">
                           <span class="fas fa-envelope"></span>
                        </div>
                     </div>
                     <div v-if="v$.user.email.$error" class="col-12 text-danger font-italic small-text">
                        <span >{{ errorMessage(v$.user.email.$errors) }}</span>
                     </div>
                  </div>
                  <div class="input-group mb-3">
                     <input v-model="v$.user.password.$model" type="password" class="form-control" placeholder="Senha">
                     <div class="input-group-append">
                        <div class="input-group-text">
                           <span class="fas fa-lock"></span>
                        </div>
                     </div>
                     <div v-if="v$.user.password.$error" class="col-12 text-danger font-italic small-text">
                        <span >{{ errorMessage(v$.user.password.$errors) }}</span>
                     </div>
                  </div>
                  <div class="input-group mb-3">
                     <input v-model="v$.user.repeatPassword.$model" type="password" class="form-control" placeholder="Repita a senha">
                     <div class="input-group-append">
                        <div class="input-group-text">
                           <span class="fas fa-lock"></span>
                        </div>
                     </div>
                     <div v-if="v$.user.repeatPassword.$error" class="col-12 text-danger font-italic small-text">
                        <span >{{ errorMessage(v$.user.repeatPassword.$errors) }}</span>
                     </div>
                  </div>
               </form>

               <div class="text-center mb-5 my-3">
                  <button
                     v-if="disabled"
                     style="border-radius: 20px"
                     title="Preenha as informações primeiro!"
                     class="btn btn-block btn-outline-secondary">
                     Paypal
                  </button>
                  <div
                     v-else
                     :key="componentKey"
                     id="paypal-button-container">
                  </div>
               </div>

               <router-link :to="{name: 'login'}" class="text-gray-primary font-italic">Já possui uma conta, clique aqui.</router-link>
            </div>
            <!-- /.form-box -->
         </div><!-- /.card -->
      </div>
   </div>
</template>

<script>
import { useToast } from "vue-toastification";
import clientService from "../service/client-service";
import { loadScript } from "@paypal/paypal-js";
import signatureService from "../../dashboard/modules/signature/services/signature-service";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import validationMixin from "../../../mixins/validationMixin";

export default {
   name: "Payment",
   setup() {
      const toast = useToast()
      return { toast, v$: useVuelidate() }
   },
   mixins: [
      validationMixin
   ],
   data () {
      return {
         paypal: null,
         user: {
            name: null,
            email: null,
            password: '',
            repeatPassword: null
         },
         disabled: true,
         componentKey: 0
      }
   },
   watch: {
      async "user.name" () {
         if (this.isOkToActivateTheButton()){
            await this.loadPaypalButton();
            this.disabled = false;
         }
      },
      async "user.email" () {
         if (this.isOkToActivateTheButton()){
            await this.loadPaypalButton();
            this.disabled = false;
         }
      },
      async "user.password" () {
         if (this.isOkToActivateTheButton()){
            await this.loadPaypalButton();
            this.disabled = false;
         }
      },
      async "user.repeatPassword"() {
         if (this.isOkToActivateTheButton()){
            await this.loadPaypalButton();
            this.disabled = false;
         }
      }
   },
   validations () {
      return {
         user: {
            name: {
               required,
               minLength: minLength(3)
            },
            email: {
               required,
               email
            },
            password: {
               required,
               minLength: minLength(6)
            },
            repeatPassword: {
               required,
               minLength: minLength(6),
               sameAsPassword: sameAs(this.user.password)
            }
         }
      }
   },
   beforeMount() {
      window.vm = this;
   },
   methods: {
      isOkToActivateTheButton () {
         if ((this.v$.user.name.$error === false && this.v$.user.name.$dirty === true ) &&
               (this.v$.user.email.$error === false && this.v$.user.email.$dirty === true) &&
               (this.v$.user.password.$error === false && this.v$.user.password.$dirty === true) &&
               (this.v$.user.repeatPassword.$error === false && this.v$.user.repeatPassword.$dirty === true)){
            return true;
         }
         else
            return false;
      },
      async loadPaypalButton () {
         if (this.disabled) {
            setTimeout(async () => {
               this.paypal = await loadScript(
                  { clientId: "ATuHK8bFNOLj5B-EdeI6SHIo7uvLWVfv4rXvgsI1j4Ln-XKqDbjx3ChaHF9eiGlGucBusduq2GnzQwgO",
                     vault: true, intent: 'subscription', currency: "BRL" }
               );

               if (this.paypal) {
                  try {
                     await this.paypal.Buttons(
                        {
                           createSubscription(data, actions) {
                              return actions.subscription.create({
                                 'plan_id': 'P-36R717910C433412UMXF7D5A'
                              });
                           },

                           async onApprove(paymentResponse) {
                              const response = await clientService.registerClient(window.vm.user);
                              const dataObj = {
                                 'uuid': response.data.userId,
                                 'userId': response.data.userId,
                                 'price': 29.90,
                                 'planId': 'P3Mb6CFE4fXZco5nIY7N',
                                 'lastSignatureId': paymentResponse.subscriptionID,
                                 'transactionId': null
                              };

                              await signatureService.createUnauthenticated(dataObj);
                              window.vm.toast.success(window.vm.$t('registration_and_payment_successfully_completed'), {
                                 timeout: 15000
                              });
                              window.vm.toast.info(
                                 window.vm.$t('now_log_in_to_the_app_to_get_full_access_to_the_articles_or_here_on_the_platform_to_see_your_payment_details'), {
                                    timeout: 20000
                                 });
                           },

                           style: {
                              layout: 'horizontal',
                              color: 'gold',
                              shape: 'pill',
                              label: 'paypal',
                              height: 32,
                              tagline: false
                           }
                        }
                     ).render("#paypal-button-container");
                  } catch (error) {
                     console.error("failed to render the PayPal Buttons", error);
                  }
               }
            }, 500);
         }
      }
   }
}
</script>

<style scoped>
.small-text {
   font-size: 12px;
   word-break: break-all;
   white-space: pre-wrap;
}
</style>
