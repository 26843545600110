import axiosClient from '@/axios'

const routeAuthenticated = '/auth/signatures';
const route = '/signatures';

const index = async () => {
   return await axiosClient.get(`${routeAuthenticated}/index`);
}

const get = async uuid => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
}

const create = async signature => {
   return await axiosClient.post(`${routeAuthenticated}/create`, signature);
}

const createUnauthenticated = async signature => {
   return await axiosClient.post(`${route}/create-unauthenticated`, signature);
}

const cancelSubscription = async (subscriptionId, signatureUuid) => {
   const data = {
      reason: "Not satisfied with the service"
   };

   const userName = process.env.VUE_APP_PAYPAL_CLIENT_ID;
   const password = process.env.VUE_APP_PAYPAL_SECRET;

   await axiosClient.post(`https://api-m.paypal.com/v1/billing/subscriptions/${subscriptionId}/cancel`, data,
      {
         auth: {
            username: userName,
            password: password
         }
      }
   );

   return await axiosClient.post(`${routeAuthenticated}/cancel-signature`, { signatureUuid } );
}

const cancelSubscriptionOnDatabase = async ( signatureUuid) => {
   return await axiosClient.post(`${routeAuthenticated}/cancel-signature`, { signatureUuid } );
}

export default {
   index,
   create,
   createUnauthenticated,
   get,
   cancelSubscription,
   cancelSubscriptionOnDatabase
}
